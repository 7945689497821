export default {
  headerView: {
    mainPage: 'Main',
    pluginPage: 'Plugin',
    pluginAll: 'All Plugin',
    pluginOwn: 'Downloaded',
    pluginUpload: 'Uploaded',
    interPage: 'inter'
  },
  footerView: {
    title: 'Devcs Tool',
    call: 'Contact Me'
  },
  sign: {
    toMainPage: 'Take Me Home',
    changeMode: 'Change Mode',
    loginIn: 'Login In',
    signUp: 'Sign Up',
    email: 'Email',
    usernameEmail: 'Username / Email',
    username: 'Username',
    password: 'Password',
    checkPass: 'Check Pass',
    code: 'Verify Code',
    codeImg: 'Code Img',
    codeTitle: 'Unable to see clearly, click refresh',
    rememberMe: 'Remember Me',
    gotoSign: 'Go To Sign Up',
    gotoLogin: 'Go To Login In',
    forgot: 'Forgot Password ?',
    other: 'Other Login',
    send: 'Send Code',
    emailCode: 'Email Verify Code'
  },
  main: {
    home: 'Take Me Home',
    errorTitle: 'Page not found',
    errorInfo: 'The page you are looking for doesn\'t exist. Here are some helpful links',
    mainLabel: 'Code Convert Tool',
    followGithub: 'Follow me on Github',
    toLogin: 'Go To Login'
  },
  label: {
    dataSource: 'Data Source',
    tableEditor: 'Table Editor',
    convertResult: 'Convert Result'
  },
  button: {
    example: 'Example',
    loadFile: 'Load File',
    extractFromUrl: 'Extract From URL',
    tip: 'Please enter the webpage URL containing the file',
    extractFile: 'Extract File',
    copyToClipboard : 'Copy To Clipboard',
    download: 'Download'
  },
  editor: {
    labelClearTip: 'Clear',
    labelCopyTip: 'Copy',
    labelUpTip: 'Increase',
    labelDownTip: 'Decrease',
    fileSize: 'file size: ',
    fontSize: 'font size: '
  },
  tableEditor: {
    tableSize: 'Table Size',
    options: {
      expandOrCollapse: 'Expand / Collapse',
      fullscreen: 'Fullscreen',
      undo: 'Undo',
      redo: 'Redo',
      transpose: 'Transpose',
      clear: 'Clear',
      deleteBlank: 'Delete Blank',
      deduplicate: 'Deduplicate',
      uppercase: 'Uppercase',
      lowercase: 'Lowercase',
      capitalize: 'Capitalize',
      replaceInput: 'Replace (Regex)',
      replaceOutput: 'Substitution',
      replaceAll: 'Replace All'
    }
  },
  optionLabel: {
    delimiter: 'Delimiter',
    CompressResults: 'Compress the results',
    singleQuotes: 'Wrap values in single quotes',
    doubleQuotes: 'Wrap values in double quotes',
    generalTable: 'Generate a create table statement'
  },
  aside: {
    explainTitlePre: 'This converter is used to convert ',
    explainTitleMid: ' into ',
    explainTitleSuf: '.',
    explainAPIPre: 'We also supported ',
    explainAPISuf: ' to convert code.',
    tip1Pre: 'Prepare the ',
    tip1Mid: ' code to convert into ',
    tip1Suf: '.',
    tip2Pre: 'An Excel-like editor allows edit the ',
    tip2Suf: ' data of previous easily.',
    tip3Pre: 'Copy or download the converted ',
    tip3Suf: ' data.',
    warning: 'We will not store any of your data.'
  },
  help: {
    helpTitle: 'How to use tools to convert code ?',
    step1Title: 'Upload or paste your source data',
    step1Info: 'Paste your source data, click Upload File, or drag and drop the source file to the Data Sources panel.',
    step2Title: 'Edit your file online, if needed',
    step2Info: 'You can edit data online like Excel through a table editor, and changes will be converted in real-time.',
    step3Title: 'Copying or downloading converted data',
    step3Info: 'A result file was generated in the conversion result box. In addition, you can customize the file format you want based on some options.',
    note: 'Note: Your data is secure, we will not store any of your data.'
  },
  explain: {
    explainTitle: 'What is {type} ?'
  },
  convertLabel: {
    intro: `Convert {inputType} into {outputType}`
  },
  footer: {
    sponsor: 'Sponsor',
    contact: 'Contact',
    privacyPolicy: 'Privacy Policy',
    about: 'About'
  },

  contactPage: {
    contactUs: 'Contact us',
    contactUsTitle: 'Your feedback is crucial!',
    contactUsInfoPre: 'Please take some time to use ',
    contactUsInfoMid: ' or ',
    contactUsInfoSuf: ' contact us. we are looking forward to hear from you!',
  },
  privacyPolicyPage: {
    privacyPolicy: 'Privacy Policy',
    privacyPolicyInfo1: 'This privacy policy discloses the privacy practices for devcs.cn. This privacy policy applies solely to information collected by this web site. It will notify you of the following:',
    privacyPolicyLi1: 'What personally identifiable information is collected from you through the web site, how it is used and with whom it may be shared.',
    privacyPolicyLi2: 'What choices are available to you regarding the use of your data.',
    privacyPolicyLi3: 'The security procedures in place to protect the misuse of your information.',
    privacyPolicyLi4: 'How you can correct any inaccuracies in the information.',
    information: 'Information Collection, Use, and Sharing',
    informationInfo: 'We do not collect data inputted or outputted via our conversion services.',
    security: 'Security',
    securityInfo: 'We will not store your input or output data in any way.',
    updates: 'Updates',
    updatesInfo: 'Our Privacy Policy may change from time to time and all updates will be posted on this page. If you feel that we are not abiding by this privacy policy, you should contact us immediately via email at devluoli@163.com or use our Contact Form.',
    registration: 'Registration',
    registrationInfo: 'During registration a user is required to give certain information (such as email address). This information is used to contact you about the products/services on our site in which you have expressed interest.',
    browserStorage: 'Browser Storage',
    browserStorageInfo: 'If available, we use the browser’s local storage to save the user’s last converted input file. The data is stored by the browser(on your computer) to enhance the user’s experience.',
    links: 'Links',
    linksInfo: 'This web site contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.'
  },
  aboutPage: {
    about: 'About',
    aboutInfo: 'DevcsTool is a web-based code conversion tool used to convert CSV, JSON, XML, YAML, SQL, HTML, Excel, and other types of data to each other.',
    solveTitle: 'What problem to solve ?',
    solveInfo1: 'Solve the mutual conversion between various types of data.',
    solveInfo2: '2D data can be operated on a table editor.',
    solveInfo3: '… Any problems to solve ?',
    acknowledgments: 'Acknowledgments',
    acknowledgmentsInfo: 'Our project could not be possible without the following wonderful open source software:'
  }
}
