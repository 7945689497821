// 配置路由
import Vue from 'vue'
import Router from 'vue-router'
// 引入插件
Vue.use(Router)

// 配置路由
const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import(/* webpackChunkName: "main" */ '@/components/MainView.vue'),
      meta: {
        skipAuth: true, // 标记该路由需要跳过登录验证
        index: '0'
      }
    },
    {
      path: '/plugin_all',
      name: 'plugin_all',
      component: () => import(/* webpackChunkName: "plugin" */ '@/views/plugin/AllPluginView.vue'),
      meta: {
        skipAuth: false, // 标记该路由需要跳过登录验证
        index: '2-1'
      }
    },
    {
      path: '/plugin_own',
      name: 'plugin_own',
      component: () => import(/* webpackChunkName: "plugin" */ '@/views/plugin/OwnPluginView.vue'),
      meta: {
        skipAuth: false, // 标记该路由需要跳过登录验证
        index: '2-2'
      }
    },
    {
      path: '/plugin_upload',
      name: 'plugin_upload',
      component: () => import(/* webpackChunkName: "plugin" */ '@/views/plugin/UploadPluginView.vue'),
      meta: {
        skipAuth: false, // 标记该路由需要跳过登录验证
        index: '2-3'
      }
    },
    {
      path: '/inter',
      name: 'inter',
      component: () => import(/* webpackChunkName: "inter" */ '@/views/plugin/InterView.vue'),
      meta: {
        skipAuth: true, // 标记该路由需要跳过登录验证
        index: '3'
      }
    },
    {
      path: '/jsoup',
      name: 'jsoup',
      component: () => import(/* webpackChunkName: "jsoup" */ '@/views/jsoup/JsoupView.vue'),
      meta: {
        skipAuth: true, // 标记该路由需要跳过登录验证
        index: '4'
      }
    },
    {
      path: '/userinfo',
      name: 'userinfo',
      component: () => import(/* webpackChunkName: "userinfo" */ '@/views/auth/UserInfoView.vue'),
      meta: {
        skipAuth: false // 标记该路由需要跳过登录验证
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/auth/LoginView.vue'),
      meta: {
        sign: true,
        skipAuth: true // 标记该路由需要跳过登录验证
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import(/* webpackChunkName: "signup" */ '@/views/auth/SignUpView.vue'),
      meta: {
        sign: true,
        skipAuth: true // 标记该路由需要跳过登录验证
      }
    },
    {
      path: '/api/oauth/callback/:platform',
      name: 'loading',
      component: () => import(/* webpackChunkName: "loading" */ '@/views/auth/LoadingView.vue'),
      meta: {
        sign: true,
        skipAuth: true // 标记该路由需要跳过登录验证
      }
    },
    {
      path: '/*',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ '@/views/error/404ErrorView.vue'),
      meta: {
        skipAuth: true // 标记该路由需要跳过登录验证
      }
    }
  ]
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  const isLoggedIn = checkIfUserLoggedIn()
  if (isLoggedIn) { // 用户已登录
    if (to.meta.sign) { // 目标页面是登录页面
      // 跳转到主页面
      next({ path: '/' })
    } else { // 目标页面是其他页面
      // 放行
      next()
    }
  } else { // 用户未登录
    if (to.meta.skipAuth) { // 目标页面是登录页面
      // 放行
      next()
    } else { // 目标页面是其他页面
      // 跳转到登录页面并记录目标页面的地址
      next({ path: '/login', query: { path: to.fullPath } })
    }
  }
})

// 检查用户是否已登录的函数
function checkIfUserLoggedIn () {
  return window.sessionStorage.getItem('token')
}

export default router
