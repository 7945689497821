// 导入axios
import axios from 'axios'

import nprogress from 'nprogress'
import 'nprogress/nprogress.css'

let baseUrl = ''
if (process.env.NODE_ENV === 'development') {
  // 在开发环境下执行的代码
  baseUrl = 'http://localhost:8888/api'
} else {
  // 在生产环境下执行的代码
  baseUrl = 'http://www.devcs.cn:8888/api'
}

// 创建 Axios 实例
const request = axios.create({
  // baseURL: ,
  baseURL: baseUrl, // 设置基本的请求路径
  timeout: 60000 // 设置请求超时时间
})

// 请求拦截器
request.interceptors.request.use(config => {
  nprogress.start()
  // 在请求头中添加 token
  let token = window.sessionStorage.getItem('token')
  if (!token) {
    token = ''
  }
  // 将 token 放入请求头
  config.headers.Authorization = `${token}`
  return config
}, error => {
  return Promise.reject(error)
})

// 阻拦响应器
request.interceptors.response.use((response) => {
  nprogress.done()
  return response
}, (error) => {
  if (error.response && error.response.status === 429) {
    const decoder = new TextDecoder('UTF-8');
    error = decoder.decode(new Uint8Array(error.response.data))
    return Promise.reject(error)
  } else {
    return Promise.reject(error)
  }
})

// 导出封装的请求方法
export default request
