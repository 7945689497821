import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const actions = {
}

const mutations = {
  setSignupInfo (state, data) {
    state.signupInfo = data
  },
  setIsLogin (state, value) {
    state.isLogin = value
  }
}

const state = {
  signupInfo: null,
  isLogin: false
}

const getters = {
  getSignupInfo (state) {
    return state.signupInfo
  },
  getIsLogin (state) {
    return state.isLogin
  }
}

export default new Vuex.Store({
  actions,
  mutations,
  state,
  getters
})
