// 引入 vue
import Vue from 'vue'
// 引入 App
import App from './App.vue'
// 引入 router
import router from './router'
// 引入 element-ui
import '@/plugins/element.js'

// 引入 store
import store from '@/vuex/store'

import http from '@/axios/http' // 引入 api.js 文件

// 全局样式
import './assets/css/global.less'

// 多语言配置
import VueI18n from 'vue-i18n'

import zh from './locales/zh.js'
import en from './locales/en.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh', // 默认语言
  messages: {
    zh,
    en
  }
})

// 将 api 实例挂载到 Vue 原型上
Vue.prototype.$http = http

// 关闭生产提示
Vue.config.productionTip = false

// 创建 VM
new Vue({
  i18n,
  store, // 将 store 注册到 Vue 实例中
  router,
  render: h => h(App)
}).$mount('#app')
