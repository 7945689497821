export default {
  headerView: {
    mainPage: '主页',
    pluginPage: '插件',
    pluginAll: '插件市场',
    pluginOwn: '已下载插件',
    pluginUpload: '已上传插件',
    interPage: '接口'
  },
  footerView: {
    title: '代码格式转换工具',
    call: '联系我：'
  },
  sign: {
    toMainPage: '去往主页',
    changeMode: '更换模式',
    loginIn: '用户登录',
    signUp: '用户注册',
    email: '邮箱',
    usernameEmail: '用户名 / 邮箱',
    username: '用户名',
    password: '密码',
    checkPass: '确认密码',
    code: '验证码',
    codeImg: '验证码图片',
    codeTitle: '看不清，点击刷新',
    rememberMe: '记住密码',
    gotoSign: '前往注册',
    gotoLogin: '前往登录',
    forgot: '已有帐号，忘记密码？',
    other: '其他方式登录',
    send: '发送验证码',
    emailCode: '邮箱验证码'
  },
  main: {
    home: '回到主页',
    errorTitle: '页面不存在',
    errorInfo: '抱歉，您当前浏览的页面不存在，您可以点击下方按钮回到主页',
    mainLabel: '代码转换工具',
    followGithub: '在 Github 上关注我',
    toLogin: '前往登录'
  },
  label: {
    dataSource: '源数据',
    tableEditor: '表格编辑器',
    convertResult: '转换结果'
  },
  button: {
    example: '示例',
    loadFile: '上传文件',
    extractFromUrl: '从 URL 中提取',
    tip: '请输入包含该文件的网页 URL',
    extractFile: '提取文件',
    copyToClipboard : '复制到剪贴板',
    download: '下载'
  },
  editor: {
    labelClearTip: '清空文本',
    labelCopyTip: '复制文本',
    labelUpTip: '放大文本',
    labelDownTip: '缩小文本',
    fileSize: '文件大小: ',
    fontSize: '字体大小: '
  },
  tableEditor: {
    tableSize: '表格大小',
    options: {
      expandOrCollapse: '展开 / 折叠',
      fullscreen: '全屏',
      undo: '撤销',
      redo: '重做',
      transpose: '转置',
      clear: '清空',
      deleteBlank: '删除空白项',
      deduplicate: '删除重复项',
      uppercase: '转大写',
      lowercase: '转小写',
      capitalize: '首字母大写',
      replaceInput: '替换的输入',
      replaceOutput: '替换的输出',
      replaceAll: '替换所有'
    }
  },
  optionLabel: {
    delimiter: '定界符',
    CompressResults: '将结果压缩',
    singleQuotes: '将结果使用单引号包裹',
    doubleQuotes: '将结果使用双引号包裹',
    generalTable: '是否生成建表语句'
  },
  aside: {
    explainTitlePre: '当前转换器用于将 ',
    explainTitleMid: ' 类型的代码转换为 ',
    explainTitleSuf: ' 类型的数据。',
    explainAPIPre: '我们还支持使用 ',
    explainAPISuf: ' 进行代码转换。',
    tip1Pre: '准备好 ',
    tip1Mid: ' 类型的代码以转换为 ',
    tip1Suf: ' 类型的数据。',
    tip2Pre: '一个类似于 Excel 的编辑器，可以轻松编辑您的 ',
    tip2Suf: ' 源数据。',
    tip3Pre: '复制或下载转换后的 ',
    tip3Suf: ' 结果数据。',
    warning: '我们不会存储您的任何数据。'
  },
  help: {
    helpTitle: '如何使用转换工具进行代码转换？',
    step1Title: '上传或者粘贴您的源文件到文本框',
    step1Info: '粘贴您的源数据，或单击上传文件，或将源文件拖放到数据源面板。',
    step2Title: '如果需要，在线编辑您的文件',
    step2Info: '您可以通过表格编辑器，像Excel一样在线编辑数据，更改将实时转换。',
    step3Title: '复制或下载转换后的文件',
    step3Info: '在转换结果框中生成了一个结果文件。此外，你可以根据一些选项自定义您想要的文件格式。',
    note: '注意：您的数据是安全的，我们不会存储您的任何数据。'
  },
  explain: {
    explainTitle: '什么是 {type} ？'
  },
  convertLabel: {
    intro: '将 {inputType} 转换为 {outputType}'
  },
  footer: {
    sponsor: '赞助',
    contact: '联系我们',
    privacyPolicy: '隐私政策',
    about: '关于我们'
  },

  contactPage: {
    contactUs: '联系我们',
    contactUsTitle: '您的反馈至关重要！',
    contactUsInfoPre: '请您花一点时间去使用 ',
    contactUsInfoMid: ' 或者 ',
    contactUsInfoSuf: ' 去联系我们。我们期待着您的来信！',
  },
  privacyPolicyPage: {
    privacyPolicy: '隐私政策',
    privacyPolicyInfo1: '此隐私政策披露devcs.cn的隐私做法。此隐私政策仅适用于此网站收集的信息。它将通知您以下信息：',
    privacyPolicyLi1: '通过网站从您那里收集了哪些个人身份信息，如何使用以及可以与谁共享。',
    privacyPolicyLi2: '关于数据的使用，您可以选择哪些选项。',
    privacyPolicyLi3: '为防止信息被滥用而制定的安全程序。',
    privacyPolicyLi4: '如何更正信息中的任何不准确之处。',
    information: '信息收集、使用和共享',
    informationInfo: '我们不收集通过我们的转换服务输入或输出的数据。',
    security: '安全',
    securityInfo: '我们不会通过任何方式去存储您的输入或输出的数据。',
    updates: '更新',
    updatesInfo: '我们的隐私政策可能会不时更改，所有更新都将发布在此页面上。如果您觉得我们不遵守此隐私政策，您应该立即通过电子邮件 devluoli@163.com 与我们联系，或使用我们的联系表。',
    registration: '注册',
    registrationInfo: '在注册过程中，用户需要提供某些信息（如电子邮件地址）。此信息用于就您表示感兴趣的我们网站上的产品/服务与您联系。',
    browserStorage: '浏览器存储',
    browserStorageInfo: '如果可以，我们使用浏览器的本地存储来保存用户最后转换的输入文件。数据由浏览器（在您的计算机上）存储，以增强用户体验。',
    links: '链接',
    linksInfo: '此网站包含指向其他网站的链接。请注意，我们不对此类其他网站的内容或隐私做法负责。我们鼓励我们的用户在离开我们的网站时注意，并阅读任何其他收集个人身份信息的网站的隐私声明。'
  },
  aboutPage: {
    about: '关于我们',
    aboutInfo: 'DevcsTool 是一个基于 web 的代码转换工具，用于将 CSV、JSON、XML、YAML、SQL、HTML、Excel 等类型的数据进行相互转换。',
    solveTitle: '要解决什么问题？',
    solveInfo1: '解决各种类型数据之间的相互转换。',
    solveInfo2: '二维数据可以在表格编辑器上的操作。',
    solveInfo3: '还可以进行的扩展……',
    acknowledgments: '鸣谢',
    acknowledgmentsInfo: '如果没有以下出色的开源软件，我们的项目就不可能实现：'
  }
}
